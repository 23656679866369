<script setup>
import { onMounted, watch, reactive, ref } from "vue";
import useInboundOrders from "../../hooks/useInboundOrders";

const props = defineProps({
  inboundOrder: {
    type: Object,
    required: false,
  },
});
const { inboundLoading, fetchInboundOrderLineItems, lineItems } = useInboundOrders();
const page = ref(1);
const localState = reactive({
  page: page.value,
  searchText: "",
  filters: [],
  sort: "id",
});

onMounted(async () => {
  if (props.inboundOrder) {
    fetchInboundOrderLineItems(props.inboundOrder.id, localState);
  }
});

watch(props.inboundOrder, async () => {
  await fetchInboundOrderLineItems(props.inboundOrder.id, localState);
});

const updatePage = (newPage) => {
  page.value = newPage;
  localState.page = newPage;
};
</script>

<template>
  <v-card class="mt-4">
    <v-card-title
      >{{ lineItems ? lineItems.total_count : "_" }} Line Item(s)</v-card-title
    >
    <v-card-text>
      <v-table>
        <thead>
          <tr>
            <th class="text-left">ID</th>
            <th class="text-left">SKU/UPC</th>
            <th class="text-left">NAME</th>
            <th class="text-left">QTY</th>
            <th class="text-left">UNITS</th>
            <th class="text-left">RECEIVED</th>
            <th class="text-left">PUT AWAY</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="!lineItems">
            <td colspan="12" class="text-center py-10">
              <v-progress-circular
                :size="40"
                :width="4"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </td>
          </tr>
          <tr
            v-for="item in lineItems.data"
            :key="item.name"
            v-if="!inboundLoading && lineItems"
          >
            <td>
              <a :href="`/products/${item.product_id}`">{{ item.product.number }}</a>
            </td>
            <td>
              {{ item.product.sku }}<br />
              {{ item.product.upc }}
            </td>
            <td>{{ item.name }}</td>
            <td>
              {{ item.qty }}
            </td>
            <td>
              {{ item.units_label }}
            </td>
            <td class="">
              {{ item.qty_received }}
            </td>
            <td>
              {{ item.qty_put_away }}
            </td>
          </tr>
        </tbody>
      </v-table>
      <v-pagination
        v-model="page"
        :length="lineItems.total_pages"
        @update:modelValue="updatePage"
        v-if="lineItems && lineItems.total_pages > 0"
      ></v-pagination>
    </v-card-text>
  </v-card>
</template>
