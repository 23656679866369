<script setup>
import { onMounted } from "vue";
import { useRoute } from "vue-router/auto";
import { setPageTitle } from "../../../utils/pageTitle";
import useInboundOrders from "../../../hooks/useInboundOrders";
import OrderShipperDetails from "../../../components/order/OrderShipperDetails.vue";
import OrderDestinationDetails from "../../../components/order/OrderDestinationDetails.vue";
import OrderOtherDetails from "../../../components/order/OrderOtherDetails.vue";
import OrderLineItems from "../../../components/order/OrderLineItems.vue";
import OrderHeader from "../../../components/order/OrderHeader.vue";
import { defineEmits } from "vue";
import InboundOrderLayout from "../../../layouts/InboundOrderLayout.vue";

const emit = defineEmits(["inboundOrder"]);
const { inboundLoading, inboundOrder, fetchInboundOrder } = useInboundOrders();
const route = useRoute();

onMounted(async () => {
  await fetchInboundOrder(route.params.id).then(() => {
    setPageTitle(`Inbound Order #IN-${inboundOrder.value.id}`);
    emit("inboundOrder", inboundOrder.value);
  });
});
</script>

<template>
  <InboundOrderLayout :inboundOrder="inboundOrder">
    <div class="w-100">
      <div v-if="!inboundLoading && inboundOrder" class="px-4">
        <div class="w-100">
          <div class="w-auto px-2">
            <OrderHeader
              orderType="inbound"
              :orderId="inboundOrder.id"
              :status="inboundOrder.order_status.short_label"
            />
          </div>

          <v-card class="mt-2 pa-2 w-auto" color="transparent" elevation="0">
            <div class="d-flex">
              <OrderShipperDetails :inboundOrder="inboundOrder" />
              <OrderDestinationDetails :inboundOrder="inboundOrder" />
              <OrderOtherDetails :inboundOrder="inboundOrder" />
            </div>
            <OrderLineItems :inboundOrder="inboundOrder" />
          </v-card>
        </div>
      </div>
    </div>
  </InboundOrderLayout>
</template>
